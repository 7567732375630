<template>
  <div class="container">
    <div class="header">
      <h1 class="fs30 marginTop5">{{ storeName }}</h1>
      <h1 class="fs20 marginBottom5">{{ deviceName }}</h1>
    </div>
    <div class="content">
      <div class="video-container">
        <i-mou-video :width="1200" :height="800" :device-id="deviceId" :channel-id="channelId" :pwd="pwd"/>
        <div class="video-control">
          <div class="button-row">
            <el-button type="primary" v-if="amplifier" class="w45" @click="handleNumberKey({key:107})" plain>放大</el-button>
            <el-button type="primary" v-else class="w45" @click="handleNumberKey({key:'5'})">停止</el-button>
            <el-button type="primary" v-if="shrink" class="w45" @click="handleNumberKey({key:109})" plain>缩小</el-button>
            <el-button type="primary" v-else class="w45" @click="handleNumberKey({key:'5'})">停止</el-button>
          </div>
          <div class="button-row">
            <el-button type="primary" v-if="seven" @click="handleNumberKey({key:'7'})" plain>左上</el-button>
            <el-button type="primary" v-else @click="handleNumberKey({key:'5'})">停止</el-button>
            <el-button type="primary" v-if="eight" @click="handleNumberKey({key:'8'})" plain>上</el-button>
            <el-button type="primary" v-else @click="handleNumberKey({key:'5'})">停止</el-button>
            <el-button type="primary" v-if="nine" @click="handleNumberKey({key:'9'})" plain>右上</el-button>
            <el-button type="primary" v-else @click="handleNumberKey({key:'5'})">停止</el-button>
          </div>
          <div class="button-row">
            <el-button type="primary" v-if="four" @click="handleNumberKey({key:'4'})" plain>左</el-button>
            <el-button type="primary" v-else @click="handleNumberKey({key:'5'})">停止</el-button>
            <el-button type="primary" style="visibility:hidden" @click="handleNumberKey({key:'5'})">停止</el-button>
            <el-button type="primary" v-if="six" @click="handleNumberKey({key:'6'})" plain>右</el-button>
            <el-button type="primary" v-else @click="handleNumberKey({key:'5'})">停止</el-button>
          </div>
          <div class="button-row">
            <el-button type="primary" v-if="one" @click="handleNumberKey({key:'1'})" plain>左下</el-button>
            <el-button type="primary" v-else @click="handleNumberKey({key:'5'})">停止</el-button>
            <el-button type="primary" v-if="two" @click="handleNumberKey({key:'2'})" plain>下</el-button>
            <el-button type="primary" v-else @click="handleNumberKey({key:'5'})">停止</el-button>
            <el-button type="primary" v-if="three" @click="handleNumberKey({key:'3'})" plain>右下</el-button>
            <el-button type="primary" v-else @click="handleNumberKey({key:'5'})">停止</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iMouVideo from "@/components/ImouVideo/iMouVideo";

export default {
  name: "videoWindows",
  components: {
    iMouVideo,
  },
  data() {
    return {
      deviceId: this.$route.query.deviceId,
      channelId: this.$route.query.channelId,
      pwd: this.$route.query.pwd,
      storeName: this.$route.query.storeName,
      deviceName: this.$route.query.deviceName,
      visibilityTimer: null, // 用于存储setTimeout的引用
      isStop: true,
      keyNumber: 0,
      kn: 0,
      amplifier: true,
      shrink: true,
      one: true,
      two: true,
      three: true,
      four: true,
      six: true,
      seven: true,
      eight: true,
      nine: true,
    }
  },
  mounted() {
    // 页面加载时开始监听可见性变化
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    // 添加键盘事件监听器
    document.body.addEventListener('keydown', this.handleNumberKey);
    // 初始化计时器
    this.startVisibilityTimer();
  },
  beforeDestroy() {
    // 正确地移除键盘事件监听器
    document.body.removeEventListener('keydown', this.handleNumberKey);
    // 组件销毁时清除事件监听和计时器
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    clearTimeout(this.visibilityTimer);
  },
  methods: {
    handle(opType) {
      let data = {
        busiType: this.operationTransformation(opType),
        ccode: this.deviceId,
        terminalId: this.channelId
      };
      this.topsAjax.post(`${this.baseUrls.device}/controlMovePTZ`, data).then((response) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
      this.stopBtn();
    },
    stopBtn() {
      this.clearStatus();
      if (this.kn === "1") {
        if (this.isStop) {
          this.one = false;
        } else {
          this.one = true;
        }
      } else if (this.kn === "2") {
        if (this.isStop) {
          this.two = false;
        } else {
          this.two = true;
        }
      } else if (this.kn === "3") {
        if (this.isStop) {
          this.three = false;
        } else {
          this.three = true;
        }
      } else if (this.kn === "4") {
        if (this.isStop) {
          this.four = false;
        } else {
          this.four = true;
        }
      } else if (this.kn === "6") {
        if (this.isStop) {
          this.six = false;
        } else {
          this.six = true;
        }
      } else if (this.kn === "7") {
        if (this.isStop) {
          this.seven = false;
        } else {
          this.seven = true;
        }
      } else if (this.kn === "8") {
        if (this.isStop) {
          this.eight = false;
        } else {
          this.eight = true;
        }
      } else if (this.kn === "9") {
        if (this.isStop) {
          this.nine = false;
        } else {
          this.nine = true;
        }
      }
    },
    clearStatus() {
      this.one = true;
      this.two = true;
      this.three = true;
      this.four = true;
      this.six = true;
      this.seven = true;
      this.eight = true;
      this.nine = true;
      this.amplifier = true;
      this.shrink = true;
    },
    handleNumberKey(event) {
      const numKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      if (numKeys.includes(event.key)) {
        this.kn = event.key;
        if (this.keyNumber === this.kn) {
          if (this.isStop) {
            this.isStop = false;
            this.kn = 5;
          } else {
            this.isStop = true;
          }
        } else {
          this.keyNumber = this.kn;
          this.isStop = true;
        }
        this.handle(this.kn);
      } else if (event.key === '+' || event.key === 107 || event.key === 61) {
        this.amplifier = !this.amplifier;
        this.handleZoom(true);
      } else if (event.key === '-' || event.key === 109 || event.key === 173) {
        this.shrink = !this.shrink;
        this.handleZoom(false);
      }
    },
    handleZoom(isZoomIn) {
      // 根据isZoomIn参数决定执行放大或缩小的操作
      let opType = isZoomIn ? 8 : 9;
      let data = {
        busiType: opType,
        ccode: this.deviceId,
        terminalId: this.channelId
      };
      this.topsAjax.post(`${this.baseUrls.device}/controlMovePTZ`, data).then((response) => {
        this.$message({
          message: isZoomIn ? "放大成功" : "缩小成功",
          type: "success",
        });
      });
    },
    operationTransformation(key) {
      let opType = 0;
      if (key === "1") {
        opType = 5;
      } else if (key === "2") {
        opType = 1;
      } else if (key === "3") {
        opType = 7;
      } else if (key === "4") {
        opType = 2;
      } else if (key === 5 || key === "5") {
        opType = 10;
      } else if (key === "6") {
        opType = 3;
      } else if (key === "7") {
        opType = 4;
      } else if (key === "8") {
        opType = 0;
      } else if (key === "9") {
        opType = 6;
      }
      return opType;
    },
    startVisibilityTimer() {
      // 当页面不可见时开始计时
      if (document.hidden) {
        this.visibilityTimer = setTimeout(() => {
          this.closeWindow();
        }, 30000); // 30秒
      }
    },
    handleVisibilityChange() {
      // 当页面可见性改变时，根据状态重置或启动计时器
      if (document.hidden) {
        // 页面变为不可见，开始计时
        this.startVisibilityTimer();
      } else {
        // 页面变为可见，重置计时器
        clearTimeout(this.visibilityTimer);
      }
    },
    closeWindow() {
      // 关闭当前窗口
      window.close();
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  height: 100vh; /* 设置容器高度为视口高度 */
}

.header {
  padding: 20px;
}

.video-container {
  flex: 1;
  position: relative; /* 保持子元素绝对定位的基础 */
}

.i-mou-video {
  width: 100%; /* 让视频占据整个容器宽度 */
  height: auto;
  pointer-events: none; /* 添加此行使得视频层不响应鼠标事件 */
}

.video-control {
  position: absolute; /* 使按钮组相对于视频定位 */
  bottom: 20px; /* 控制按钮距离底部的距离 */
  right: 20px; /* 控制按钮距离右侧的距离 */
  display: flex;
  flex-direction: column; /* 列方向布局 */
  gap: 10px; /* 控制按钮间的垂直间距 */
  z-index: 999999; /* 确保按钮组在最上面 */
}

.button-row > .el-button {
  width: calc(33.33% - 10px); /* 计算每个按钮的宽度，减去间隔 */
  margin-right: 0; /* 移除右侧间隔 */
}

.button-row > .el-button:last-child {
  margin-right: auto; /* 最后一个按钮右侧自动间隔 */
}
.w45 {
  width: 45% !important;
}
</style>
