var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "header" }, [
      _c("h1", { staticClass: "fs30 marginTop5" }, [
        _vm._v(_vm._s(_vm.storeName)),
      ]),
      _c("h1", { staticClass: "fs20 marginBottom5" }, [
        _vm._v(_vm._s(_vm.deviceName)),
      ]),
    ]),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "video-container" },
        [
          _c("i-mou-video", {
            attrs: {
              width: 1200,
              height: 800,
              "device-id": _vm.deviceId,
              "channel-id": _vm.channelId,
              pwd: _vm.pwd,
            },
          }),
          _c("div", { staticClass: "video-control" }, [
            _c(
              "div",
              { staticClass: "button-row" },
              [
                _vm.amplifier
                  ? _c(
                      "el-button",
                      {
                        staticClass: "w45",
                        attrs: { type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: 107 })
                          },
                        },
                      },
                      [_vm._v("放大")]
                    )
                  : _c(
                      "el-button",
                      {
                        staticClass: "w45",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "5" })
                          },
                        },
                      },
                      [_vm._v("停止")]
                    ),
                _vm.shrink
                  ? _c(
                      "el-button",
                      {
                        staticClass: "w45",
                        attrs: { type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: 109 })
                          },
                        },
                      },
                      [_vm._v("缩小")]
                    )
                  : _c(
                      "el-button",
                      {
                        staticClass: "w45",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "5" })
                          },
                        },
                      },
                      [_vm._v("停止")]
                    ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "button-row" },
              [
                _vm.seven
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "7" })
                          },
                        },
                      },
                      [_vm._v("左上")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "5" })
                          },
                        },
                      },
                      [_vm._v("停止")]
                    ),
                _vm.eight
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "8" })
                          },
                        },
                      },
                      [_vm._v("上")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "5" })
                          },
                        },
                      },
                      [_vm._v("停止")]
                    ),
                _vm.nine
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "9" })
                          },
                        },
                      },
                      [_vm._v("右上")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "5" })
                          },
                        },
                      },
                      [_vm._v("停止")]
                    ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "button-row" },
              [
                _vm.four
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "4" })
                          },
                        },
                      },
                      [_vm._v("左")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "5" })
                          },
                        },
                      },
                      [_vm._v("停止")]
                    ),
                _c(
                  "el-button",
                  {
                    staticStyle: { visibility: "hidden" },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handleNumberKey({ key: "5" })
                      },
                    },
                  },
                  [_vm._v("停止")]
                ),
                _vm.six
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "6" })
                          },
                        },
                      },
                      [_vm._v("右")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "5" })
                          },
                        },
                      },
                      [_vm._v("停止")]
                    ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "button-row" },
              [
                _vm.one
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "1" })
                          },
                        },
                      },
                      [_vm._v("左下")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "5" })
                          },
                        },
                      },
                      [_vm._v("停止")]
                    ),
                _vm.two
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "2" })
                          },
                        },
                      },
                      [_vm._v("下")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "5" })
                          },
                        },
                      },
                      [_vm._v("停止")]
                    ),
                _vm.three
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "3" })
                          },
                        },
                      },
                      [_vm._v("右下")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleNumberKey({ key: "5" })
                          },
                        },
                      },
                      [_vm._v("停止")]
                    ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }